<template>

<diV>

  
  <div class="card" style="max-height: 100px" >
    <div class="card-header">
    
    <b-row>
      <b-col
         md="2"
         xl="2"
      >
        <b-form-group
          label="Nº OP:"
        >
          <b-form-input
            id="IDIPROC"
            placeholder=""
            v-model="ApontamentoProducao.idiproc"
            readonly
            autocomplete="off"
          />
        </b-form-group>
      </b-col>
      <b-col
        md="6"
        xl="6"
      >
        <b-form-group
          label="Produto:"
        >
          <b-form-input
            placeholder=""
            v-model="ApontamentoProducao.descrprod"
            required
            readonly
            autocomplete="off"
          />
        </b-form-group>
      </b-col>

      <b-col
         md="4"
         xl="4"
      >
        <b-form-group
          label="Etapa:"
        >
          <b-form-input
            id="Etapa"
            placeholder=""
            v-model="ApontamentoProducao.descricaoetapa"
            readonly
            autocomplete="off"
          />
        </b-form-group>
      </b-col>


    </b-row>
  </div>
  </div>
  
  <div class="card">
    
    <b-row>
      <b-col
        md="12"
        xl="12"
      >
    <b-table
      striped       
      responsive
      sticky-header
      :items="this.ApontamentoProducao.ciclos"
      :fields="fields"
      table-class = "font-size11" 
      style="max-height: 360px;"
    >

    <template #cell(idiproc)="row">
    <div>
      <div class="DivBotaoLadoLado col-lg-1 col-md-1 col-xl-1" >
          <b-form-group label="Ciclo" >
            <b-form-input 
                    id="AD_CICLO"
                    v-model="row.item.AD_CICLO"
                    class="font-size11"
                    :readonly="row.item.SITUACAO !== 'C' ? false : ''"
                    autocomplete="off"
                    @input="EditarCiclo(row.item)"
                  />
          </b-form-group>
          
        
       </div>

       <div class="DivBotaoLadoLado col-lg-2 col-md-2 col-xl-2 InputVlr">
          <b-form-group label="Qtd. Boa" >
             <b-form-input 
                    id="QTDAPONTADA"
                    v-model="row.item.QTDAPONTADA"
                    class="font-size11"
                    type="number"
                    autofocus
                    autocomplete="off"
                    :readonly="row.item.SITUACAO !== 'C' ? false : ''"
                    @input="EditarCiclo(row.item)"
                  />
          </b-form-group>
       </div>

       <div class="DivBotaoLadoLado col-lg-2 col-md-2 col-xl-2 InputVlr"  >
          <b-form-group label="Qtd. Perda" >
             <b-form-input 
                    id="QTDPERDA"
                    v-model="row.item.QTDPERDA"
                    class="font-size11"
                    type="number"
                    autocomplete="off"
                    :readonly="row.item.SITUACAO !== 'C' ? false : ''"
                    @input="EditarCiclo(row.item)"
                  />
          </b-form-group>
       </div>
      

       <div class="DivBotaoLadoLado" >
          <b-form-group label="Data Inicio" >
            <b-form-input 
                    id="AD_DHINICIO"
                    v-model="row.item.AD_DHINICIO"
                    class="font-size11"
                    type="date"
                    @change="AtualizaDataBase"
                    autocomplete="off"
                    :readonly="row.item.SITUACAO !== 'C' ? false : ''"
                    @input="EditarCiclo(row.item)"
                  />
          </b-form-group>
       </div>

       <div class="DivBotaoLadoLado"  >  
         <b-form-group label="Hora Inicio" >
           <b-form-input
             id="AD_DHINICIO_HORA"
             v-model="row.item.AD_DHINICIO_HORA"
             class="font-size11"
             type="time"
             autocomplete="off"
             :readonly="row.item.SITUACAO !== 'C' ? false : ''"
             @input="EditarCiclo(row.item)"
           />
         </b-form-group>                   
      </div>

      <div class="DivBotaoLadoLado " >
          <b-form-group label="Data Fim" >
             <b-form-input
                    id="AD_DHFINAL"
                    v-model="row.item.AD_DHFINAL"
                    class="font-size11"
                    type="date"
                    @change="AtualizaDataBase"
                    autocomplete="off"
                    :readonly="row.item.SITUACAO !== 'C' ? false : ''"
                    @input="EditarCiclo(row.item)"
                  />
          </b-form-group>
       </div>

       <div class="DivBotaoLadoLado" >  
         <b-form-group label="Hora Fim" >
           <b-form-input
             id="AD_DHFINAL_HORA"
             v-model="row.item.AD_DHFINAL_HORA"
             class="font-size11"
             type="time"
             @change="AtualizaHoraBase"
             autocomplete="off"
             :readonly="row.item.SITUACAO !== 'C' ? false : ''"
             @input="EditarCiclo(row.item)"
           />
         </b-form-group>                   
      </div>


      <div class="DivBotaoLadoLado col-1" >  
         <b-form-group label="" >
          
          
          <div class="DivBotaoLadoLado" v-if="CicloEditando =='' && row.item.SITUACAO !== 'C'"  >  
            <b-button 
               variant="danger"
               size="sm" @click="RemoveCiclo(row.item)" class="mr-1 CtmBtn">
               <feather-icon
                   :icon="'Trash2Icon'"
                   size="15"
                />
              Excluir
            </b-button>
           </div>


          <div class="DivBotaoLadoLado" v-if="row.item.NUAPO == CicloEditando && row.item.SITUACAO !== 'C'" >  
              <b-button 
                 variant="warning"
                 size="sm" @click="CancelarCiclos(row.item)" class="mr-1 CtmBtn">
                 <feather-icon
                     :icon="'ArrowLeftCircleIcon'"
                     size="15"
                  />
                 Cancelar
              </b-button>
          </div>

          <div class="DivBotaoLadoLado"  v-if="row.item.NUAPO == CicloEditando && row.item.SITUACAO !== 'C'" >  
          
          <b-button 
             variant="secondary"
             size="sm" @click="AtualizarAddCiclo(row.item)" class="mr-1 CtmBtn">
            <feather-icon
                 :icon="'CheckCircleIcon'"
                 size="15"
            />
            Atualizar+1            
          </b-button>

          </div>

          <div class="DivBotaoLadoLado"  v-if="row.item.NUAPO == CicloEditando && row.item.SITUACAO !== 'C'" >  
          
          <b-button 
             variant="success"
             size="sm" @click="AtualizarCiclo(row.item)" class="mr-1 CtmBtn">
             <feather-icon
                 :icon="'CheckIcon'"
                 size="15"
              />
              Atualizar            
          </b-button>

          </div>  

          <div class="DivBotaoLadoLado"  v-if="CicloEditando =='' " >  
              <b-button 
                 variant="primary"
                 size="sm" @click="RedirecionaMateriais(row.item)" class="mr-1 CtmBtn" >
                  <feather-icon
                     :icon="'LayersIcon'"
                     size="15"
                  />
                  Materiais
                 
              </b-button>
          </div>
          

          <div class="DivBotaoLadoLado"  v-if="CicloEditando =='' " >  
              <b-button 
                 variant="secondary"
                 size="sm" @click="RedirecionaPerda(row.item)" class="mr-1 CtmBtn">
                  <feather-icon
                     :icon="'TrendingDownIcon'"
                     size="15"
                  />
                  Perdas
                 
              </b-button>
          </div>

         </b-form-group>                   
      </div>

      
      
     
    </div>
      </template>
 

    </b-table>
      
      </b-col>
    </b-row>

   <b-row>
      <b-col
        md="12"
        xl="12"
        class="mb-1"
      >

      <div class="DivBotaoLadoLado">
          <b-button v-if="PermiteAddCiclo"
            variant="success"
            @click="AddCiclo"
          >
          <feather-icon
           :icon="'PlusCircleIcon'"
           size="15"
           /> 
            <span> Ciclo</span>
          </b-button>
      </div>

      <div class="DivBotaoLadoLado" >
            <b-button
           variant="danger"
           type="button"
           @click="$router.push('../ApontamentoProducao')"
         >
           <span>RETORNAR</span>
         </b-button>
      </div>

      <div class="DivBotaoLadoLado" v-if="PermiteAddCiclo && 1 ===2"  >
          <b-button 
            variant="primary"
            type="button"
            @click="ConcluirApontamento"
          >

          <abbr title="Confirmar todos os Ciclos">
          <feather-icon
           :icon="'CheckIcon'"
           size="15"
           /> 
            
          </abbr>
          <span> CONFIRMAR</span>
          </b-button>
     </div>

    </b-col>

    </b-row>
  
</div>
  </diV>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import {
  BFormInput, BRow, BCol, BFormGroup, BButton, BFormDatepicker,BTable, BFormSelect,
} from 'bootstrap-vue'
import apiPortalSky from '@/services/apiPortalSky'
import AlertaMsg from '@/customPortal/AlertaMsg.vue'
import ConexaoAPI from '@/customPortal/ConexaoAPI.vue'
import Spinner from '@/components/Spinner.vue'

export default {
  components: {
    BCardCode,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BButton,
    BFormDatepicker,
    BTable,
    BFormSelect,
  },
  data() {
    return {
      ApontamentoProducao: {
        idiproc: "",
        versao: "",
        codprc: "",
        codprod: "",
        descrprod: "",
        qtdproduzir: "",
        descricaoetapa: "",
        descstatus:"",
        ciclos:[],
        formulario:[]
      },
      DataBase:'',
      HoraBase:'',
      fields: [
        { key: 'idiproc', label: ''  }
      ],
      PermiteAddCiclo: false,
      PermiteConfirmarCiclo: false,
      CicloEditando: '',
      AtualizarIncluir:false
    }
  },
  methods: {
    async AtualizaDataBase(data) {
      this.DataBase = data
    },
    async AtualizaHoraBase(hora) {
      this.HoraBase = hora
    },
    async AddCiclo() {
      
      if(this.AtualizarIncluir == false){
        Spinner.StarLoad()
      }
      
      let NewCiclo =
           {
            IDIPROC: this.ApontamentoProducao.idiproc,
            IDIATV: this.ApontamentoProducao.idiatv,
            NUAPO:null, 
            QTDAPONTADA:0,
            QTDPERDA:0,
            AD_DHINICIO: this.DataBase,
            AD_DHINICIO_HORA: this.HoraBase,
            AD_DHFINAL: this.DataBase,
            AD_DHFINAL_HORA: '',
            SITUACAO:'P'
           }
      
      let notification = ''
      
      const usuario = this.$session.get('usuario')
      const URLpost = `/skOperacoesProducao/CriaApontamento?CODUSU=${usuario.codusu}&NOMUSU=${usuario.nomeusu}&INTERNO=${usuario.interno}`
      // Carrega os Dados no Objeto para enviar no Parametro
      const ParametroJson = JSON.parse(JSON.stringify(NewCiclo))
      await apiPortalSky.post(URLpost, ParametroJson, {
        headers: {
          'Content-Type': 'application/json',
        },
      }).then(response => {
        if (response.status === 200) {
          this.ConsultaCiclos()
        }
      })
        .catch(error => {
          notification = ConexaoAPI.ValidaErro(error)
          AlertaMsg.FormataMsg(notification)
        })

        if(notification === '') {
         Spinner.EndLoad()
        }
    },
    async RemoveCiclo(Ciclo) {
      Spinner.StarLoad()
      
      let notification = ''
      
      let NUAPO = Ciclo.NUAPO
      
      const usuario = this.$session.get('usuario')
      const URLpost = `/skOperacoesProducao/RemoveApontamentoCiclo?CODUSU=${usuario.codusu}&NOMUSU=${usuario.nomeusu}&INTERNO=${usuario.interno}&NUAPO=${NUAPO}`
      // Carrega os Dados no Objeto para enviar no Parametro
      // const ParametroJson = JSON.parse(JSON.stringify(NewCiclo))
      await apiPortalSky.post(URLpost, NUAPO, {
        headers: {
          'Content-Type': 'application/json',
        },
      }).then(response => {
        if (response.status === 200) {
          this.ConsultaCiclos()
        }
      })
        .catch(error => {
          notification = ConexaoAPI.ValidaErro(error)
          AlertaMsg.FormataMsg(notification)
        })

        if(notification === '') {
         Spinner.EndLoad()
        }
    },
    async EditarCiclo(Ciclo) {

      if(this.CicloEditando == '' || this.CicloEditando == Ciclo.NUAPO)
      {
        this.PermiteAddCiclo = false
        this.CicloEditando = Ciclo.NUAPO
      }else{
  
        let notification = { message: "Conclua a Edição do Ciclo anterior", alert_type: 'attention' }
        AlertaMsg.FormataMsg(notification)
      }

    },
    async CancelarCiclos(Ciclo) {
      this.CicloEditando = ""
      this.PermiteAddCiclo = true
      
      this.ConsultaCiclos()

    },    
    async AtualizarAddCiclo(Ciclo) {
      
      this.AtualizarIncluir = true
      await this.AtualizarCiclo(Ciclo)
      await this.AddCiclo()
      this.AtualizarIncluir = false
    
   },
    async AtualizarCiclo(Ciclo) {
     
      Spinner.StarLoad()

      let notification = ''

      Ciclo.QTDAPONTADA = parseFloat(Ciclo.QTDAPONTADA)
      Ciclo.QTDPERDA = parseFloat(Ciclo.QTDPERDA)
      
      const usuario = this.$session.get('usuario')
      const URLpost = `/skOperacoesProducao/AtualizaApontamentoCiclo?CODUSU=${usuario.codusu}&NOMUSU=${usuario.nomeusu}&INTERNO=${usuario.interno}`
      // Carrega os Dados no Objeto para enviar no Parametro
      const ParametroJson = JSON.parse(JSON.stringify(Ciclo))
      await apiPortalSky.post(URLpost, ParametroJson, {
        headers: {
          'Content-Type': 'application/json',
        },
      }).then(response => {
        
        if (response.status === 200) {
          this.ConsultaCiclos()
        }
      })
        .catch(error => {
          notification = ConexaoAPI.ValidaErro(error)
          AlertaMsg.FormataMsg(notification)
        })

        if(notification === '') {
         
         if(this.AtualizarIncluir == false){
          Spinner.EndLoad()
         }

         this.CicloEditando = ""
         this.PermiteAddCiclo = true
        }

    },
    async ConsultaCiclos() {

      if(this.AtualizarIncluir == false){
        Spinner.StarLoad()
      }

      let notification = ''
      const usuario = this.$session.get('usuario')
      
      const URLpost = `/skOperacoesProducao/ConsultaApontamentoProducaoCiclo?CODUSU=${usuario.codusu}&IDIPROC=${this.ApontamentoProducao.idiproc}&IDIATV=${this.ApontamentoProducao.idiatv}`
      //const ParametroJson = JSON.parse(JSON.stringify(this.ApontamentoProducao))

      await apiPortalSky.post(URLpost, this.ApontamentoProducao.IDIPROC).then(response => {
        if (response.status === 200) {
          this.DescarregaDadosDoObjeto(response.data)          
        }
      }).catch(error => {
        notification = ConexaoAPI.ValidaErro(error)
        AlertaMsg.FormataMsg(notification)
      })

      if(notification === '') {
         
         if(this.AtualizarIncluir == false){
          Spinner.EndLoad()
         }
      }
    },
    async ConcluirApontamento() {
      Spinner.StarLoad()
      
      let notification = ''
      let MsgErro = ''
      
      let IDIPROC =  this.ApontamentoProducao.idiproc;
      let IDIATV =  this.ApontamentoProducao.idiatv;
      
      const usuario = this.$session.get('usuario')
      const URLpost = `/skOperacoesProducao/ConfirmarApontamento?CODUSU=${usuario.codusu}&NOMUSU=${usuario.nomeusu}&INTERNO=${usuario.interno}&IDIPROC=${IDIPROC}&IDIATV=${IDIATV}`
      // Carrega os Dados no Objeto para enviar no Parametro
      // const ParametroJson = JSON.parse(JSON.stringify(NewCiclo))
      await apiPortalSky.post(URLpost, IDIPROC, {
        headers: {
          'Content-Type': 'application/json',
        },
      }).then(response => {
        if (response.status === 200) {
          this.ConsultaCiclos()

          MsgErro = `Apontamento Concluido com Sucesso`
          notification = { message: MsgErro, alert_type: 'success' }
          
          // Faz a navegação para a Tela de Lista
          AlertaMsg.FormataMsg(notification)
        }
      })
        .catch(error => {
          notification = ConexaoAPI.ValidaErro(error)
          AlertaMsg.FormataMsg(notification)
        })

        if(notification === '') {
         Spinner.EndLoad()
        }
    },
    DescarregaDadosDoObjeto(data) {
      this.ApontamentoProducao.ciclos = data.CICLOS
      if(this.ApontamentoProducao.status !=='F'){
        
        this.PermiteAddCiclo = true

        let CiclosNaoConfirmados = this.ApontamentoProducao.ciclos.find(item => item.SITUACAO != 'C')
        if(CiclosNaoConfirmados != null){
         this.PermiteConfirmarCiclo = true
        }
        

      }
      
    },
    RedirecionaPerda(item) {
    this.$session.set('ApontamentoProducao', this.ApontamentoProducao)
    const $rota = `../apontamentoProducaoDetalheForm/${item.AD_CICLO}/${item.IDEFX}`
    this.$router.push($rota)
    
    },
    RedirecionaMateriais(item) {
    this.$session.set('ApontamentoProducao', this.ApontamentoProducao)
    const $rota = `../apontamentoProducaoDetalheMateriais/${item.AD_CICLO}`
    this.$router.push($rota)
    
    },
  },
  mounted() {
    if (this.$session.exists('ApontamentoProducao')) {
        this.ApontamentoProducao = this.$session.get('ApontamentoProducao')
        this.DataBase = this.ApontamentoProducao.dT_ULTIMOCICLO
        this.HoraBase = this.ApontamentoProducao.hR_ULTIMOCICLO
        this.ConsultaCiclos()
    }else{
      this.$router.push('../ApontamentoProducao')
    }
    
  },
}
</script>


<style>
    .InputVlr{
       width: 110px;
      }
    .CtmBtn{
       width: 110px;
      }
</style>